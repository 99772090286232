<template>
  <div>
    <b-button variant="primary" size="sm" class="mb-2" @click="addItem">
      Adicionar
    </b-button>
    <b-table striped hover :fields="fields" :items="indicator.values">
      <template #cell(reference_date)="{ index }">
        <b-form-input
          type="date"
          v-model="indicator.values[index].reference_date"
          :state="validateStateValues(index, 'reference_date')"
        ></b-form-input>
      </template>
      <template #cell(value)="{ index }">
        <NumberFormat
          v-model="indicator.values[index].value"
          :decimals="indicator.decimals"
        />
      </template>
      <template #cell(actions)="{ index }">
        <b-button @click="deleteItem(index)" size="sm" variant="danger"
          >Excluir</b-button
        >
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";
import NumberFormat from "@/components/Form/NumberFormat.vue";

export default {
  components: { NumberFormat },
  mixins: [validationMixin],
  data() {
    return {
      teste: 1523.21,
      fields: [
        {
          key: "reference_date",
          label: "Data de Referência",
          sortable: true
        },
        {
          key: "value",
          label: "Valores",
          sortable: true
        },
        {
          key: "actions",
          label: "#",
          sortable: false
        }
      ]
    };
  },
  validations: {
    indicator: {
      values: {
        $each: {
          reference_date: {
            required: requiredIf(function(data) {
              return data.value !== null && data.value !== "";
            })
          },
          value: {}
        }
      }
    }
  },
  computed: {
    ...mapState("indicators", ["indicator"]),
    numberFormatOptions() {
      return {
        precision: this.indicator.decimals,
        prefix: "",
        suffix: "",
        decimal: ",",
        thousand: ".",
        acceptNegative: true,
        isInteger: this.indicator.decimals > 0
      };
    }
  },
  methods: {
    ...mapActions("users", ["ActionNew"]),
    validateStateValues(index, name) {
      const { $dirty, $error } = this.$v.indicator.values.$each.$iter[index][
        name
      ];
      return $dirty ? !$error : null;
    },
    isValid() {
      this.$v.indicator.$touch();
      return !this.$v.indicator.$anyError;
    },

    addItem() {
      this.indicator.values.push({
        reference_date: null,
        value: null,
        value_text: null
      });
    },
    deleteItem(index) {
      this.$bvModal
        .msgBoxConfirm("Você tem certeza que deseja excluir?", {
          okVariant: "danger",
          okTitle: "SIM",
          cancelVariant: "info",
          cancelTitle: "NÃO"
        })
        .then(value => {
          if (value) {
            this.indicator.values.splice(index, 1);
          }
        });
    }
  },
  async created() {
    await this.ActionNew({});
  }
};
</script>

<style></style>
