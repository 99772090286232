<template>
  <b-form-select v-model="model" :options="options"></b-form-select>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    value: {
      type: Number,
    },
  },
  data: () => ({
    sources: [],
  }),
  computed: {
    options() {
      return this.sources.map((item) => ({ value: item.id, text: item.name }));
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions("source", ["ActionFind"]),
  },

  async created() {
    this.sources = await this.ActionFind();
  },
};
</script>

<style>
</style>
