<template>
  <div>
    <b-form-select v-model="model" :options="options"></b-form-select>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    value: {
      type: Number,
    },
  },
  methods: {
    ...mapActions("unities", ["ActionGetAll"]),
  },
  computed: {
    ...mapState("unities", ["unities"]),
    options() {
      return this.unities.map((item) => ({ value: item.id, text: item.name }));
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  created() {
    this.ActionGetAll();
  },
};
</script>

<style></style>
