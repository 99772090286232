<template>
  <div>
    <b-form-group label="Sigla:">
      <b-form-input
        v-model="indicator.initials"
        placeholder="Sigla"
        :state="validateState('initials')"
      ></b-form-input>
      <b-form-invalid-feedback
        >Sigla é um campo obrigatório.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group label="Nome:">
      <b-form-input
        v-model="indicator.name"
        placeholder="Nome"
        :state="validateState('name')"
      ></b-form-input>
      <b-form-invalid-feedback
        >Nome é um campo obrigatório.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Descrição:">
      <b-form-textarea
        v-model="indicator.description"
        placeholder="Descrição"
        rows="4"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group label="Fonte:">
      <SelectFontes
        v-model="indicator.source_id"
        :state="validateState('source_id')"
      />
      <b-form-invalid-feedback :state="validateState('source_id')"
        >Fonte é um campo obrigatório.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Tipo de Valores:">
      <b-form-radio v-model="indicator.data_type" value="value"
        >Valores numéricos</b-form-radio
      >
      <b-form-radio v-model="indicator.data_type" value="text"
        >Valores em texto</b-form-radio
      >

      <b-form-invalid-feedback :state="validateState('data_type')"
        >Tipo é um campo obrigatório.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group v-if="indicator.data_type == 'value'" label="Casas decimais:">
      <b-form-select
        v-model="indicator.decimals"
        :options="decimalPlaces"
      ></b-form-select>
    </b-form-group>

    <b-form-group label="Unidade:">
      <SelectUnities
        v-model="indicator.unity_id"
        :state="validateState('unity_id')"
      />
    </b-form-group>

    <b-form-group label="Frequência:">
      <b-form-select
        v-model="indicator.frequency"
        placeholder="Frequência"
        :options="frequencies"
        :state="validateState('frequency')"
      />
      <b-form-invalid-feedback
        >Frequência é um campo obrigatório.</b-form-invalid-feedback
      >
    </b-form-group>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { mask } from "vue-the-mask";
import SelectFontes from "@/components/Form/SelectFontes";
import SelectUnities from "@/modules/unities/components/SelectUnities";

export default {
  components: { SelectUnities, SelectFontes },
  mixins: [validationMixin],
  data: () => ({
    decimalPlaces: [0, 1, 2, 3, 4, 5],
    frequencies: [
      { value: "year", text: "Ano" },
      { value: "month", text: "Mês" },
      { value: "daily", text: "Dia" },
    ],
  }),
  validations: {
    indicator: {
      initials: {
        required,
      },
      name: {
        required,
      },
      source_id: {
        required,
      },
      data_type: {
        required,
      },
      unity_id: {
        required,
      },
      frequency: {
        required,
      },
    },
  },
  computed: {
    ...mapState("indicators", ["indicator"]),
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.indicator[name];
      return $dirty ? !$error : null;
    },
    isValid() {
      this.$v.indicator.$touch();
      return !this.$v.indicator.$anyError;
    },
  },
  async created() {},
  directives: { mask },
};
</script>
