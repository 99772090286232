<template>
  <b-form @submit="onSubmit">
    <b-card no-body v-if="indicator" class="radius-top-0">
      <template #header>
        <div class="d-flex justify-content-between align-items-center">
          <b class="text-secondary">{{ getMetaAction }}</b>
          <div class="d-flex flex-row-reverse">
            <b-button
              v-if="$can('indicator:create')"
              type="submit"
              variant="primary"
              >Salvar</b-button
            >

            <b-button @click="$router.back()" variant="link" class="mr-2"
              >Cancelar</b-button
            >
          </div>
        </div>
      </template>

      <b-tabs pills card vertical class="edit-menu-sidebar">
        <b-tab active>
          <template #title>
            <font-awesome-icon
              icon="exclamation-triangle"
              class="text-warning"
              v-if="!validations.dados_basicos"
            />
            Indicador
          </template>
          <DadosBasicos ref="dados_basicos" />
        </b-tab>

        <b-tab title="Permissoes">
          <template #title>
            <font-awesome-icon
              icon="exclamation-triangle"
              class="text-warning"
              v-if="!validations.permissoes"
            />
            Valores de indicadores
          </template>
          <IndicatorValue ref="permissoes" />
        </b-tab>
      </b-tabs>
    </b-card>
  </b-form>
</template>

<script>
import DadosBasicos from "./Form/DadosBasicos.vue";
import IndicatorValue from "../components/Form/IndicatorValue";
import { mapActions, mapState } from "vuex";

export default {
  components: { DadosBasicos, IndicatorValue },
  data: () => ({
    validations: {
      dados_basicos: true,
      permissoes: true,
    },
  }),
  computed: {
    ...mapState("indicators", ["indicator"]),

    getMetaAction() {
      return this.$route.meta.action;
    },
  },
  methods: {
    ...mapActions("indicators", ["ActionSave"]),

    async onSubmit(event) {
      event.preventDefault();

      if (!this.isValid()) {
        this.$notifications.addNotification({
          message: "Os dados do formulário são inválidos",
          type: "danger",
        });
      } else {
        try {
          await this.ActionSave(this.indicator);
          this.$emit("saved", this.indicator.id);

          this.$notifications.addNotification({
            message: "Registro salvo com sucesso",
            type: "success",
          });
        } catch (error) {
          console.log("error", error);
          let error_message = "Erro ao salvar";

          this.$notifications.addNotification({
            message: error_message,
            type: "danger",
          });
        }
      }
    },
    isValid() {
      this.validations.dados_basicos = this.$refs.dados_basicos.isValid();
      this.validations.permissoes = this.$refs.permissoes.isValid();

      return Object.values(this.validations).every(Boolean);
    },
    changeComponent($event) {
      return (this.currentComponent = $event);
    },
  },
};
</script>

<style></style>
