<template>
  <b-form-input
    v-model="local_value"
    :autofocus="autofocus"
    :disabled="disabled"
    :form="form"
    :id="id"
    :name="name"
    :placeholder="placeholder"
    :required="required"
    :size="size"
    :state="state"
    @blur="blur"
    @focus="focus"
    @keypress="keypress"
  />
</template>

<script>
import {
  formControlMixin,
  props as formControlProps
} from "bootstrap-vue/esm/mixins/form-control";

import {
  formStateMixin,
  props as formStateProps
} from "bootstrap-vue/esm/mixins/form-state";

import { sortKeys } from "bootstrap-vue/esm/utils/object";
import { makePropsConfigurable } from "bootstrap-vue/esm/utils/props";

const props = makePropsConfigurable(
  sortKeys({
    ...formStateProps,
    ...formControlProps,
    value: 0,
    placeholder: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: "md"
    },
    decimals: {
      type: Number,
      default: 0
    }
  })
);

export default {
  mixins: [formControlMixin, formStateMixin],
  props,
  data: () => ({}),
  computed: {
    potencia() {
      return Math.pow(10, this.decimals);
    },
    format_values() {
      let format = "0,000";
      if (this.decimals > 0) {
        format += "." + "".padEnd(this.decimals, "0");
      }
      return format;
    },
    local_value: {
      set(value) {
        value = value.toString();

        const isNegative = value[0] == "-";

        value = value.toString().replace(/[^\d]/g, "");
        value = parseInt(value) / this.potencia;

        value *= isNegative ? -1 : 1;

        this.$emit("input", value);
      },
      get() {
        let response = this.$options.filters.number_format(
          this.value,
          this.format_values
        );
        return response;
      }
    },
    has_value() {
      return this.value ? true : false;
    },

    classes() {
      let classes = ["form-control", "datepicker"];
      if (this.stateClass) {
        classes.push(this.stateClass);
      }

      if (this.size != "md") {
        classes.push("form-control-" + this.size);
      }

      return classes;
    }
  },
  methods: {
    clearValue() {
      this.local_value = null;
    },
    blur() {
      this.$emit("blur");
    },
    focus() {
      this.$emit("focus");
    },
    keypress(evt) {
      let keyCode = evt.keyCode ? evt.keyCode : evt.which;

      if ((keyCode < 48 || keyCode > 57) && keyCode != 45) {
        evt.preventDefault();
      }
    }
  },
  watch: {},
  async created() {}
};
</script>
